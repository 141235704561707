<template>
  <div id="app">
    <router-view></router-view>
    <van-tabbar v-model="active">
      <van-tabbar-item to="/"
                       icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item to="/Weather">
        <template #icon>
          <i class="iconfont icon-tianqi"></i>
        </template>
        天气
      </van-tabbar-item>
      <van-tabbar-item to="/Mine"
                       icon="friends-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>


export default {
  name: 'App',
  data () {
    return {
      active: 0,
    }
  },

  methods: {

  },

  computed: {

  },


  watch: {

  },


  created () {

  },

  mounted () {
    this.$router.push('/')
  },

  destroyed () {

  },

}

</script>

<style>
.van-tabbar-item .icon-tianqi {
  font-size: inherit;
}
</style>
