import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: '/',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/Weather',
      name: 'Weather',
      component: () => import('@/views/Weather.vue')
    },
    {
      path: '/Mine',
      name: 'Mine',
      component: () => import('@/views/Mine.vue')
    },
    {
      path: '/Port',
      name: 'Port',
      component: () => import('@/views/Port.vue')
    },
    {
      path: '/TideCalendar',
      name: 'TideCalendar',
      component: () => import('@/views/TideCalendar.vue')
    },
    {
      path: '/LocationSearch',
      name: 'LocationSearch',
      component: () => import('@/views/LocationSearch.vue')
    },

  ]
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router