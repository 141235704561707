import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/global.css'
import './assets/style/iconfont.css'
import './assets/style/iconWeather.css'
import 'element-ui/lib/theme-chalk/index.css'

//引入vant
import { Button, Icon, Tabbar, TabbarItem, Col, Row, TreeSelect, NavBar, calendar, Search, DatetimePicker, Popup, } from 'vant';
Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Icon);
Vue.use(TreeSelect);
Vue.use(NavBar);
Vue.use(calendar);
Vue.use(Search);
Vue.use(DatetimePicker);
Vue.use(Popup);


Vue.config.productionTip = false


//函数节流
const on = Vue.prototype.$on
Vue.prototype.$on = function (event, func) {
  let previous = 0
  let newFunc = func
  if (event === 'click') {
    newFunc = function () {
      const now = new Date().getTime()
      if (previous + 1500 <= now) {
        func.apply(this, arguments)
        previous = now
      }
    }
  }
  on.call(this, event, newFunc)
}


new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
