import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {

  },

  // getters
  getters: {

  },

  // actions
  actions: {

  },

  // mutations
  mutations: {

  },

})

export default store